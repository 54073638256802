import styles from './LogoLoader.module.scss';
import React from "react";

type LogoLoaderType = {
    opacity: number;
    zIndex?: number;
}

const LogoLoader: React.FC<LogoLoaderType> = ({
        opacity,
        zIndex=999,
    }) =>
     {

    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                display: "flex",
                position: "absolute",
                justifyContent: "center",
                alignItems: "center",
                opacity: opacity,
                transitionProperty: "opacity z-index",
                transitionDuration: "1s",
                zIndex: zIndex,
                backgroundColor: window.Telegram.WebApp.themeParams.bg_color,
            }}
        >
            <button
                className={styles.button}
            >
                <span>&nbsp;dancen&nbsp;</span>
                <span
                    className={styles.hoverText}
                    style={{}}
                >&nbsp;dancen&nbsp;</span>
            </button>
        </div>

    )
}
export default LogoLoader;