import axios, { AxiosRequestConfig, Method } from 'axios';
import {toast} from "react-toastify";
import {errorWords} from "@/backend/const";

interface RequestParams {
  method: Method;
  url: string;
  data?: any;
  onSuccess: (response: any) => void;
  onError?: (error: any) => void;
}

const defaultErrorHandler = (error: any) => {
  window.Telegram.WebApp.HapticFeedback.notificationOccurred("error");
  window.Telegram.WebApp.MainButton.setParams(
      {
          color: window.Telegram.WebApp.themeParams.button_color,
          text_color: window.Telegram.WebApp.themeParams.button_text_color,
      }
  );
  window.Telegram.WebApp.MainButton.hideProgress();
  Object.entries(error.response.data).forEach(([key, value]) => {
      toast.error(`${key in errorWords ? errorWords[key] : key} - ${value.toString()}`);
  })
  throw error;
  // console.error('Произошла ошибка при выполнении запроса:', error);
  // Здесь могут быть дополнительные действия по обработке ошибок, например, отображение уведомления для пользователя
};

export const fetchData = async ({
  method,
  url,
  data = {},
  onSuccess,
  onError = defaultErrorHandler,
}: RequestParams) => {
  url = process.env.REACT_APP_API_URL + url;
  try {
    const config: AxiosRequestConfig = {
      method,
      url,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'authorization': `Token ${localStorage.getItem('token')}`,
      },
      ...(method === 'GET' ? { params: data } : { data }),
    };

    const response = await axios(config);
    onSuccess(response.data);
  } catch (error) {
    onError(error);
  }
};
