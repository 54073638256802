import React from 'react';
import {fetchData} from "@/backend/requests";

interface MeSettingsContextType {
  settingsData: SettingsData | null;
  localSettingsData: SettingsData | null;
  setLocalSettingsData?: React.Dispatch<React.SetStateAction<SettingsData>>;
}

const MeSettingsContext = React.createContext<MeSettingsContextType | undefined>(undefined);

type MeSettingsContextProviderType = {
  children: React.ReactNode,
  setReadeTyShow: (ready:boolean) => void,
  token: string,
}

export const MeSettingsContextProvider: React.FC<MeSettingsContextProviderType> = (
    {
        children,
        setReadeTyShow,
        token,
    }
) => {

  const apiUrl = process.env.REACT_APP_API_URL;

  // Настройки, которые хранятся на сервере
  const [settingsData, setSettingsData] = React.useState<SettingsData | null>(null);
  // Локальные настройки, которые хранятся локально до того, как будут сохранены
  const [localSettingsData, setLocalSettingsData] = React.useState<SettingsData | null>(null);



  React.useEffect(() => {
    if (token){
      fetchData({
        method: 'GET',
        url: 'api/user-data/web-app/v1/users/me/settings/',
        onSuccess: (response) => {
          setSettingsData(response);
          setReadeTyShow(true);
        },
        onError: (error) => {},
      }).catch();
    }
  }, [token, apiUrl, setReadeTyShow])


  React.useEffect(() => {
    if(localSettingsData){
      fetchData({
        method: 'PATCH',
        url: 'api/user-data/web-app/v1/users/me/settings/',
        data: localSettingsData,
        onSuccess: (response) => {
          setSettingsData(response);
          setLocalSettingsData(null);
        },
        onError: (error) => {},
      }).catch();
    }
  }, [localSettingsData, apiUrl]);

  React.useEffect(() => {
    if (settingsData) {
      if (settingsData.confirm_closing_application) {
        window.Telegram.WebApp.enableClosingConfirmation();
      } else {
        window.Telegram.WebApp.disableClosingConfirmation();
      }
    }
  }, [settingsData])


  const contextValue: MeSettingsContextType = {
    settingsData,
    localSettingsData,
    setLocalSettingsData,
  };

  return <MeSettingsContext.Provider value={contextValue}>{children}</MeSettingsContext.Provider>;
};

export const useMeSettingsContext = () => {
  const context = React.useContext(MeSettingsContext);

  if (!context) {
    throw new Error('Context Error!');
  }

  return context;
};

