import axios, {AxiosResponse} from 'axios';

interface AuthResponse {
  token: string;
}

export const authenticate = async (): Promise<string> => {
    const apiUrl = process.env.REACT_APP_API_URL;

    try {

        let WebApp = window.Telegram.WebApp;
        let initDataURLSP = new URLSearchParams(WebApp.initData);
        let hash = initDataURLSP.get('hash');

        const response: AxiosResponse<AuthResponse> = await axios.post<AuthResponse>(
            apiUrl+'api/user-request/web-app/v1/login/',
            {
                Hash: hash,
                InitData: WebApp.initData,
            }
        );

        return response.data.token;

    } catch (error) {

        console.error('Authentication failed:', error);

        return '';
    }
};
