import React from 'react';
import axios from 'axios';


interface EnumsContextType {
  enumsData: EnumsData | null;
}

const EnumsContext = React.createContext<EnumsContextType | undefined>(undefined);

type EnumsContextProviderType = {
  children: React.ReactNode,
  setReadeTyShow: (ready:boolean) => void,
  token: string,
}

export const EnumsContextProvider: React.FC<EnumsContextProviderType> = (
    {
        children,
        setReadeTyShow,
        token,
    }
) => {

  const apiUrl = process.env.REACT_APP_API_URL;

  const [enumsData, setEnumsData] = React.useState<EnumsData | null>(null);



  React.useEffect(() => {
    if (token){
      const fetchUserData = async () => {
        try {

          const response = await axios.get<EnumsData>(
              apiUrl + 'api/main/web-app/v1/enums/',
              {
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json;charset=UTF-8',
                  'Access-Control-Allow-Origin': '*',
                  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                  'authorization': `Token ${localStorage.getItem('token')}`,
                },
              }
          );
          setEnumsData(response.data);
          setReadeTyShow(true);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };
      fetchUserData().catch();
    }
  }, [token, apiUrl, setReadeTyShow])


  const contextValue: EnumsContextType = {
    enumsData,
  };

  return <EnumsContext.Provider value={contextValue}>{children}</EnumsContext.Provider>;
};

export const useEnumsContext = () => {
  const context = React.useContext(EnumsContext);

  if (!context) {
    throw new Error('Context Error!');
  }

  return context;
};

