import React from 'react';
import axios from 'axios';



interface MeContextType {
  userData: UserData | null;
  setUserData: React.Dispatch<React.SetStateAction<UserData>>;
}

const MeContext = React.createContext<MeContextType | undefined>(undefined);

type MeContextProviderType = {
  children: React.ReactNode,
  setReadeTyShow: (ready:boolean) => void,
  token: string,
}
// Компонент-поставщик (Provider), который предоставляет значение контекста
export const MeContextProvider: React.FC<MeContextProviderType> = (
    {
        children,
        setReadeTyShow,
        token,
    }
) => {

  const apiUrl = process.env.REACT_APP_API_URL;

  const [userData, setUserData] = React.useState<UserData | null>(null);



  React.useEffect(() => {
    if (token){
      const fetchUserData = async () => {
        try {

          const response = await axios.get<UserData>(
              apiUrl + 'api/user-data/web-app/v1/users/me/',
              {
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json;charset=UTF-8',
                  'Access-Control-Allow-Origin': '*',
                  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                  'authorization': `Token ${localStorage.getItem('token')}`,
                },
              }
          );
          setUserData(response.data);
          setReadeTyShow(true);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };
      fetchUserData().catch();
    }
  }, [token, apiUrl, setReadeTyShow])


  const contextValue: MeContextType = {
    userData,
    setUserData,
  };

  return <MeContext.Provider value={contextValue}>{children}</MeContext.Provider>;
};

export const useMeContext = () => {
  const context = React.useContext(MeContext);

  if (!context) {
    throw new Error('Context Error!');
  }

  return context;
};

