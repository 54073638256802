import {GiConverseShoe} from "react-icons/gi";
import React from "react";
import {HiOutlineUserGroup} from "react-icons/hi2";
import {BsFillPersonFill} from "react-icons/bs";
import {FaRegStar} from "react-icons/fa";

export const danceTypesNames = {
    groupLesson: 'group_lesson',
    individualLesson: 'individual_lesson',
    rent: 'rent',
    masterClass: 'master_class',
    intensive: 'intensive',
}

export const danceTypes = {
    [danceTypesNames.groupLesson]: {
        title: 'Групповое',
        icon: <HiOutlineUserGroup />,
        description: 'Групповое занятие',
        fields: [
            'teacher',
            'participant',
            'participant_user_ids',
            'studio',
            'datetime',
            'dance_styles_ids',
        ],
        requiredFields: [

        ],
    },
    [danceTypesNames.individualLesson]: {
        title: 'Индивидуальное',
        icon: <BsFillPersonFill />,
        description: 'Индивидуальное занятие с педагогом',
        fields: [
            'teacher',
            'participant',
            'participant_user_ids',
            'studio',
            'datetime',
        ],
        requiredFields: [

        ],
    },
    [danceTypesNames.rent]: {
        title: 'Аренда студии',
        icon: <GiConverseShoe />,
        description: 'Аренда для индивидуального или совместного занятия',
        fields: [
            'studio',
            'datetime',
        ],
        requiredFields: [
            'studio',
            'datetime',
        ],
    },
    [danceTypesNames.masterClass]: {
        title: 'Мастер-класс',
        icon: <FaRegStar />,
        description: 'Мастер-класс',
        fields: [
            'teacher',
            'participant',
            'participant_user_ids',
            'studio',
            'datetime',
            'dance_styles_ids',
        ],
        requiredFields: [

        ],
    },
    // [danceTypesNames.intensive]: {
    //     title: 'Интенсив',
    //     icon: <SiWireguard />,
    //     description: 'Интенсив',
    //     fields: [
    //
    //     ],
    //     requiredFields: [
    //
    //     ],
    // },
}
export const months = [
    "Янв",
    "Фев",
    "Мар",
    "Апр",
    "Май",
    "Июн",
    "Июл",
    "Авг",
    "Сен",
    "Окт",
    "Ноя",
    "Дек",
];

export const getTitleDuration = (min: number) => {
    let hour = Math.floor(min/60);

    let hourStr = Boolean(hour) ? (hour + 'ч') : '';
    let minStr = Boolean((min - (hour * 60))) ? (min - (hour * 60)) + 'мин' : '';
    return (hourStr && hourStr) + ' ' + (minStr && minStr)
}

export const errorWords = {
    studio: 'Студия',
    date_event: 'Дата события',
    styles: 'Стили',
    dance_styles_ids: 'Стили',
    participant_teacher_ids: 'Педагог',
    participant_user_ids: 'Участники',
    type: 'Тип события',
    city_id: 'Город',
    first_name: 'Имя',
    last_name: 'Фамилия',
}

export const getStringDate = (dateInit: string) => {
    let result = ''

    if (dateInit) {
        let date = new Date(dateInit);
        let hours = date.getHours().toString().padStart(2, '0'); // Получаем часы
        let minutes = date.getMinutes().toString().padStart(2, '0'); // Получаем минуты

        result = `${date.getDate()} ${months[date.getMonth()]} ${hours}:${minutes}`;
    }
    return result
}