import LogoLoader from "@/components/Loaders/LogoLoader";
import React, {lazy, Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'src/style/global.scss';
import {authenticate} from "@/backend/login";
import {MeContextProvider} from "@/contexts/me";
import {MeSettingsContextProvider} from "@/contexts/meSettings";
import {EnumsContextProvider} from "@/contexts/enums";
import {BrowserRouter as Router} from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

window.Telegram.WebApp.history = {
    historyList: [],
    add: (params) => {
        window.Telegram.WebApp.history.historyList.unshift(params);
        window.Telegram.WebApp.history.backRender();
        window.Telegram.WebApp.history.mainRender();
    },
    addMainButton: (params) => {
        if (window.Telegram.WebApp.history.historyList.length === 0) {
            window.Telegram.WebApp.history.historyList.unshift({});
        }
        window.Telegram.WebApp.history.historyList[0].mainButtonParams = params.mainButtonParams;
        window.Telegram.WebApp.history.historyList[0].mainFunc = params.mainFunc;
        window.Telegram.WebApp.history.mainRender();
        window.Telegram.WebApp.history.backRender();

    },
    clickBack: () => {
        let params = window.Telegram.WebApp.history.historyList.shift();
        params.backFunc(params.state) && window.Telegram.WebApp.history.historyList.unshift(params);
        window.Telegram.WebApp.history.backRender();
        window.Telegram.WebApp.history.mainRender();
    },
    clickMain: () => {
        let params = window.Telegram.WebApp.history.historyList.shift();
        window.Telegram.WebApp.history.historyList.unshift(params);
        if (params.mainFunc()){
            window.Telegram.WebApp.history.clickBack();
        }
        window.Telegram.WebApp.history.backRender();
        window.Telegram.WebApp.history.mainRender();
    },
    updateMainFunc: (func) => {
        if (window.Telegram.WebApp.history.historyList.length) {
            window.Telegram.WebApp.history.historyList[0].mainFunc = func;
        }
    },
    removeStep: () => {
        window.Telegram.WebApp.history.historyList.shift();
        window.Telegram.WebApp.history.backRender();
        window.Telegram.WebApp.history.mainRender();
    },
    backRender: () => {
        if (window.Telegram.WebApp.history.historyList.length && window.Telegram.WebApp.history.historyList[0].backFunc){
            window.Telegram.WebApp.BackButton.show();
        } else {
            window.Telegram.WebApp.BackButton.hide();
        }
    },
    mainRender: () => {
        if (
            window.Telegram.WebApp.history.historyList.length &&
            window.Telegram.WebApp.history.historyList[0].mainFunc
        ) {
            window.Telegram.WebApp.MainButton.setParams(window.Telegram.WebApp.history.historyList[0].mainButtonParams);
            window.Telegram.WebApp.MainButton.show();
        } else {
            window.Telegram.WebApp.MainButton.hide();
        }
    },
    removeMainButton : () => {
        if (window.Telegram.WebApp.history.historyList.length) {
            window.Telegram.WebApp.history.historyList[0].mainFunc = null;
            window.Telegram.WebApp.history.historyList[0].mainButtonParams = null;
        }
        window.Telegram.WebApp.history.removeMainButton();
    },
    clearHistory: () => {
        window.Telegram.WebApp.history.historyList = [];
        window.Telegram.WebApp.history.backRender();
        window.Telegram.WebApp.history.mainRender();
    }
}

window.Telegram.WebApp.BackButton.onClick(()=>{
    window.Telegram.WebApp.history.clickBack();
});

window.Telegram.WebApp.MainButton.onClick(()=>{
    window.Telegram.WebApp.history.clickMain();
});

if (!window.Telegram.WebApp.isExpanded) {
    window.Telegram.WebApp.expand();
}

const App: React.FC = () => {
    const [isLoadMainApp, setIsLoadMainApp] = React.useState(false); // Индикатор загрузки основного приложения
    const [token, setToken] = React.useState(null); // Токе

    if(!window.Telegram.WebApp.BackButton.isVisible){
        window.Telegram.WebApp.BackButton.hide();
    }

    const [meContextReadyToShow, setMeContextReadyToShow] = React.useState(false); // Получили данные из ручки /me
    const [meSettingsContextReadyToShow, setMeSettingsContextReadyToShow] = React.useState(false); // Получили данные из ручки /settings
    const [enumsContextReadyToShow, setEnumsContextReadyToShow] = React.useState(false); // Получили данные из ручки /enums
    const [readyToShow, setReadyToShow] = React.useState(false); // Приложение готово к загрузке

    const MainApp = lazy(() => import('@/MainApp'));

    React.useEffect(() => {
        const loadComponent = async () => {
            await App; // Дождитесь, пока компонент полностью не загрузится
            setIsLoadMainApp(true);
        };
        loadComponent().then(r => {});
        authenticate().then((token: string) => {
            localStorage.setItem('token', token);
            setToken(token);
        });
    },
        []
    );

    React.useEffect(() => {
        if(
            isLoadMainApp
            && token
            && meContextReadyToShow
            && meSettingsContextReadyToShow
            && enumsContextReadyToShow
        ){
            setReadyToShow(true);
        }
    }, [isLoadMainApp, token, meContextReadyToShow, meSettingsContextReadyToShow, enumsContextReadyToShow]);

    return <div>
        <Router>
            <MeContextProvider setReadeTyShow={setMeContextReadyToShow} token={token}>
                <MeSettingsContextProvider setReadeTyShow={setMeSettingsContextReadyToShow} token={token}>
                    <EnumsContextProvider setReadeTyShow={setEnumsContextReadyToShow} token={token}>
                        <LogoLoader
                            opacity={readyToShow ? 0 : 1}
                            zIndex={readyToShow ? -10 : 9999}
                        />
                        <Suspense
                            fallback={
                            <LogoLoader
                                opacity={readyToShow ? 0 : 1}
                                zIndex={readyToShow ? -10 : 9999}
                            />
                        }
                        >
                            {readyToShow && <MainApp />}
                        </Suspense>
                    </EnumsContextProvider>
                </MeSettingsContextProvider>
            </MeContextProvider>
        </Router>
    </div>;
};

root.render(<App />);
